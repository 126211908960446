define("ember-content-editable-modifier/modifiers/content-editable", ["exports", "ember-modifier", "@ember/destroyable", "@ember/object"], function (_exports, _emberModifier, _destroyable, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  /* eslint-disable prettier/prettier */
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function cleanup(instance) {
    instance.element?.removeEventListener('input', instance.domUpdated);
  }
  let ContentEditableModifier = (_class = class ContentEditableModifier extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "didSetup", false);
      _defineProperty(this, "element", void 0);
      _defineProperty(this, "value", undefined);
      (0, _destroyable.registerDestructor)(this, cleanup);
    }
    modify(element, positional, named) {
      this.onChange = named.onChange;
      if (!this.didSetup) {
        this.element = element;
        if (named.placeholder) {
          element.setAttribute('placeholder', named.placeholder);
        }
        element.classList.add('ember-content-editable');
        element.addEventListener('input', this.domUpdated);
        if (named.autofocus) {
          element.focus();
        }
        this.didSetup = true;
      }
      if (this.value != named.value) {
        this.updateValue(named.value);
      }
      if (named.disabled && element.getAttribute('contenteditable')) {
        element.removeAttribute('contenteditable');
      } else if (!named.disabled && !element.getAttribute('contenteditable')) {
        element.setAttribute('contenteditable', 'true');
      }
    }
    updateValue(value) {
      this.value = value;
      if (this.value) {
        this.element.innerText = this.value;
      } else {
        this.element.innerText = '';
      }
    }
    domUpdated() {
      this.value = this.element.innerText;
      if (this.onChange) {
        this.onChange(this.value);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "domUpdated", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "domUpdated"), _class.prototype)), _class);
  _exports.default = ContentEditableModifier;
});